import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { createGuid } from './constant';
import { appInsights } from './AppInsight';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import * as Sentry from '@sentry/react'
import { refreshToken } from '../store/slices/CompanySlice';


export const baseUrl = process.env.REACT_APP_VAULT_API_URL;
let infraBaseUrl = process.env.REACT_APP_INFRA_API_URL;
let operationsBaseUrl = process.env.REACT_APP_OPERATIONS_API_URL;


export const AxiosInstance = axios.create({
	baseURL: baseUrl,
});

const InfraAxiosInstance = axios.create({
	baseURL: infraBaseUrl,
});

export const OperationsAxiosInstance = axios.create({
	baseURL:operationsBaseUrl
})

export const setupAxiosInterceptors = () => {
	const onRequestSuccess = async (config: any) => {
		let authToken = localStorage.getItem('access_token');
		let decodedAuthToken: { exp: number} | null = null;
		let exp = 0

		if(authToken){
			decodedAuthToken = jwt_decode(authToken);
			if(decodedAuthToken?.exp){
				exp = decodedAuthToken.exp * 1000
			}
		}
		
		const CorrelationId = localStorage.getItem('correlationId');
		if (!authToken || !decodedAuthToken || exp < 1 || Date.now() >= exp) {
			
			if (!authToken) {  
				const refreshTokenRequest = await refreshToken()
				let authToken:string  = refreshTokenRequest.idToken;        
				localStorage.setItem('access_token', authToken);
			  }
		}

		if (!CorrelationId) {
			const generatedGuid = createGuid();
			config.headers['X-Correlation-ID'] = generatedGuid;
			localStorage.setItem('correlationId', generatedGuid);
		}

		if (authToken) {
			config.headers['Authorization'] = 'Bearer ' + authToken;
		}

		if (CorrelationId) {
			config.headers['X-Correlation-ID'] = CorrelationId;
		}
		
		return config;
	};

	const onRequestError = (err: any) => {
		const userData = JSON.parse(localStorage.getItem('userData')!)
		
		Sentry.setUser({ 
			email: userData?.email, 
			userName: `${userData?.firstName} ${userData?.lastName} ${userData?.displayName}` ,
			id: userData.userId
		});
		Sentry.captureException(err)

		return Promise.reject(err);
	};

	const onResponseSuccess = (response:any) => response;

	const onResponseError = async(err:any) => {
		console.log(err)

		if (err.response && (err.response.status === 401)) {
			const refreshTokenRequest = await refreshToken()
			let authToken:string  = refreshTokenRequest.idToken;        
			localStorage.setItem('access_token', authToken);
			window.location.reload()
			return false
		}

		const userData = JSON.parse(localStorage.getItem('userData')!)
		const CorrelationId = sessionStorage.getItem('correlationId')

		Sentry.setUser({ 
			email: userData?.email, 
			userName: `${userData?.firstName} ${userData?.lastName} ${userData?.displayName}` ,
			id: userData?.userId
		});

		if (err.response && err.response.status === 400) {
			//console.log(err)
			//console.log(err.response.data.message)
			Sentry.setContext("Bad Request", {
				data: err?.response?.data,
				error: JSON.stringify(err?.response?.data),
                payload: JSON.stringify(JSON.parse(err?.response?.config?.data)),
				failingEndpoint: err?.response?.request?.responseURL,
				userEmail: userData?.email,
				isAdmin: userData?.isAdmin,
				userType: userData?.userType,
				hasSubscription: userData?.hasCurrentSubscription
			});
			Sentry.captureException(err)
			return Promise.reject(err)
		}
		if (err.response && err.response.status === 404) {
			Sentry.setContext("404 error", {
				data: err.response.data,
				error: JSON.stringify(err?.response?.data),
                payload: JSON.stringify(JSON.parse(err?.response?.config?.data)),
				failingEndpoint: err?.response?.request?.responseURL,
				userEmail: userData?.email,
				isAdmin: userData?.isAdmin,
				userType: userData?.userType,
				hasSubscription: userData?.hasCurrentSubscription
			});
			Sentry.captureException(err)
			return Promise.reject(err)
		}
		if (err.response && err.response.status === 500) {
			Sentry.setContext("Server error", {
				data: err?.response?.data,
				error: JSON.stringify(err?.response?.data),
                payload: JSON.stringify(JSON.parse(err?.response?.config?.data)),
				failingEndpoint: err?.response?.request?.responseURL,
				userEmail: userData?.email,
				isAdmin: userData?.isAdmin,
				userType: userData?.userType,
				hasSubscription: userData?.hasCurrentSubscription
			});
			Sentry.captureException(err)
			return Promise.reject(err)
		}
		Sentry.captureException(err)

		appInsights.trackException(
			{ error: new Error(err), severityLevel: SeverityLevel.Error },
			{ email: userData?.email, 'X-Correlation-ID': CorrelationId, Frontend_Project: 'AutoComply', data: JSON.stringify(err?.response?.data) }
		);

		return Promise.reject(err);
	};

	AxiosInstance.interceptors.request.use(onRequestSuccess, onRequestError);
	AxiosInstance.interceptors.response.use(onResponseSuccess, onResponseError);
	InfraAxiosInstance.interceptors.request.use(onRequestSuccess, onRequestError);
	InfraAxiosInstance.interceptors.response.use(onResponseSuccess, onResponseError);
	OperationsAxiosInstance.interceptors.request.use(onRequestSuccess, onRequestError);
	OperationsAxiosInstance.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default AxiosInstance;
