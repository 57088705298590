import React, { createContext, useContext, useEffect, useRef, ReactNode } from 'react'
import { SignalRService } from '../services/signalRService'

interface SignalRContextProps {
    connectionId: string | null
    signalRService: SignalRService | null
    addEventHandler: (event: string, handler: (data: any) => void) => void
    removeEventHandler: (event: string, handler: (data: any) => void) => void
}

const SignalRContext = createContext<SignalRContextProps | null>(null)

interface SignalRProviderProps {
    url: string
    children: ReactNode
}

export const SignalRProvider: React.FC<SignalRProviderProps> = ({ url, children}) => {
    const signalRService = useRef<SignalRService | null>(null)
    const connectionId = useRef<string | null>(null)

    useEffect(() => {
        signalRService.current = new SignalRService(url);

        signalRService.current?.on('ReceiveConnectionId', (socketConnectionId: string) => {
            connectionId.current = socketConnectionId
            const companyId = sessionStorage.getItem('activeCompanyId')
            if (companyId) {
                signalRService.current?.send('SubscribeCompany', companyId, socketConnectionId)
            }
            sessionStorage.setItem('socketConnectionId', socketConnectionId)
        })
    
        return () => {
          signalRService.current?.close();
        };
      }, [url]);

    const addEventHandler = (event: string, handler: (data: any) => void) => {
        signalRService.current?.on(event, handler);
    };

    const removeEventHandler = (event: string, handler: (data: any) => void) => {
        signalRService.current?.off(event, handler);
    };

    return (
        <SignalRContext.Provider value={{ signalRService: signalRService.current, addEventHandler, removeEventHandler, connectionId: connectionId.current }}>
            {children}
        </SignalRContext.Provider>
    )
}

export const useSignalR = () => {
    const context = useContext(SignalRContext)
    if (!context) {
        throw new Error('UserSignalR must be used within a SignalRProvider')
    }

    return context
}