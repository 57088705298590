// AppInsights.js
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const domainName =  window.location.hostname;
let instrumentationKey = '18fa6b75-cc33-4b8d-926f-e4ae0747f225';

const ResolveUrl =()=>{
  switch(true){
    case domainName.toLowerCase().indexOf('df')>-1:
      instrumentationKey = '6b44e025-770d-4688-9996-ed879a6241e0'
      break;
    case domainName.toLowerCase().indexOf('dev')>-1:
    case domainName.toLowerCase().indexOf('localhost')>-1:
      instrumentationKey = '3ff637cd-6913-49fb-8049-14d553870efa';
      break;
    default:
      instrumentationKey = '18fa6b75-cc33-4b8d-926f-e4ae0747f225';
  }
}

ResolveUrl();

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: instrumentationKey,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
  },
});
appInsights.loadAppInsights();
export { reactPlugin, appInsights };
