import { toast } from "react-toastify";
import axios from "../../utils/AxiosConfig";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UserProfile, TeamMemberType, UsersPageRecord, AddMemberPayload, UserCompanies, UserInfo, PendingInvites  } from "../../utils/typings/settings";
import { companyApi } from './CompanySlice';


export const teamApi = createApi({
    reducerPath: 'teamApi',
    tagTypes: ['UserProfile', 'UsersByPage', 'UserCompanies', 'UserInfo', 'PendingUsers'],
    keepUnusedDataFor: 30 * (60 * 1000),
    refetchOnReconnect: true,
    baseQuery: fetchBaseQuery({ baseUrl: '/' }),
    endpoints: (builder) => ({
        getUserProfile: builder.query<UserProfile, void>({
            queryFn: () => {
                return axios.get(`api/Users/profile`)
                    .then((response) => {
                        localStorage.setItem('userData', JSON.stringify(response.data.result))
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
						return Promise.reject(new Error(response.data))
					});
            },
            async onQueryStarted(queryArg, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled
                    dispatch(companyApi.util.invalidateTags(['DasboardStatistics'])) //invalidate query in another api

                } catch (err) {
                }
            },
            providesTags: () => [{ type: 'UserProfile' }]
        }),
		getUserInfo: builder.query<UserInfo, string>({
            queryFn(userId) {
                return axios.get(`api/Users/${userId}`).then((response) => {
                    return {
                        data: response.data.result
                    }
                })
                .catch(({ response }) => {
					return Promise.reject(new Error(response.data))
				});
            },
			providesTags: () => [{ type: 'UserInfo' }]
        }),
        updateUserProfile: builder.mutation<UserProfile, { profilePictureUrl: string, lastName: string, firstName: string}>({
            queryFn: (url) => {
                return axios.post(`api/Users/profile/edit`, url)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
						toast.error(response.data.message)
						return Promise.reject(new Error(response.data))
					});
            },
            invalidatesTags: () => [{ type: 'UserProfile' }]
        }),
        getTeamMembers: builder.query<TeamMemberType[], string>({
            queryFn(companyId: string) {
                return axios.get(`api/Companies/${companyId}/teams`).then((response) => {
                    return {
                        data: response.data.result
                    }
                })
                .catch(({ response }) => {
					return Promise.reject(new Error(response.data))
				});
            },
        }),
        getUsersByPage: builder.query<UsersPageRecord, void>({
            queryFn() {
                return axios.get('api/Users/get-users-paged/1/50/{}').then((response) => {
                    return {
                        data: response.data.result
                    }
                })
                .catch(({ response }) => {
					return Promise.reject(new Error(response.data))
				});
            },
			providesTags: () => [{ type: 'UsersByPage' }]
        }),
        getPendingUsers: builder.query<PendingInvites[], string>({
            queryFn() {
                return axios.get('api/Users/pending-invitations').then((response) => {
                    return {
                        data: response.data.result
                    }
                })
                .catch(({ response }) => {
					return Promise.reject(new Error(response))
				});
            },
			providesTags: () => [{ type: 'PendingUsers' }]
        }),
		addTeamMember: builder.mutation<void, AddMemberPayload>({
            queryFn: (payload) => {
                return axios.post('api/Users/add-team-member', payload)
                    .then((response) => {
                        toast.success('User added successfully')
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
						toast.error(response.data.message)
						return Promise.reject(new Error(response.data))
					});
            },
            invalidatesTags: () => ['PendingUsers', 'UserCompanies']
        }),
		editTeamMember: builder.mutation<void, AddMemberPayload>({
            queryFn: (payload) => {
                return axios.put('api/Users/update-team-member', payload)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
						toast.error(response.data.message)
						return Promise.reject(new Error(response.data))
					});
            },
            invalidatesTags: () => [{ type: 'UsersByPage' }]
        }),
		getUserCompanies: builder.query<UserCompanies[], void>({
            queryFn() {
                return axios.get('api/Users/get-all-companies').then((response) => {
                    return {
                        data: response.data.result
                    }
                })
                .catch(({ response }) => {
					return Promise.reject(new Error(response.data))
				});
            },
			providesTags: () => [{ type: 'UserCompanies' }]
        }),
		deleteTeamMember: builder.mutation<void, { emailAddress: string, userId: string}>({
            queryFn: (data) => {
                return axios.delete(`api/Users/remove-user/${data.userId}`, { data: { taskReassignee: data.emailAddress }})
                    .then((response) => {
                        toast.success('Team member deleted successfully')
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
						toast.error(response.data.message)
						throw new Error(response)
					});
            },
            invalidatesTags: () => ['UsersByPage', 'UserCompanies']
        }),
		resendInvitation: builder.mutation<void, string>({
            queryFn: (userId) => {
                return axios.get(`api/Users/${userId}/resend-invitation`)
                    .then((response) => {
                        toast.success('Invite resent')
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
						toast.error(response.data.message)
						throw new Error(response)
					});
            }
        }),
        revokeInvitation: builder.mutation<void, string>({
            queryFn: (userId) => {
                return axios.delete(`api/Users/${userId}/cancel-invitation`)
                    .then((response) => {
                        toast.success('Invite revoked')
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
						toast.error(response.data.message)
						throw new Error(response)
					});
            },
            invalidatesTags: () => ['PendingUsers']
        }),
    })
})


export const { 
	useGetUserProfileQuery, useGetTeamMembersQuery, useUpdateUserProfileMutation,
	useGetUsersByPageQuery, useAddTeamMemberMutation, useEditTeamMemberMutation,
	useGetUserCompaniesQuery, useDeleteTeamMemberMutation, useGetUserInfoQuery,
    useGetPendingUsersQuery, useResendInvitationMutation, useRevokeInvitationMutation
} = teamApi


