import axios, { baseUrl } from '../../utils/AxiosConfig';
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AllNotifications, NotificationChannels, NotificationItem, NotificationSettings, NotificationSettingsKeys } from '../../utils/typings/settings';
import { toast } from 'react-toastify';


export const notificationApi = createApi({
    reducerPath: 'notificationApi',
    tagTypes: ['NotificationSettings', 'NotificationChannels', 'Notifications'],
    keepUnusedDataFor: 30 * (60 * 1000),
    refetchOnReconnect: true,
    baseQuery: fetchBaseQuery({ baseUrl: '/' }),
    endpoints: (builder) => ({
        fetchNotificationSettings: builder.query<NotificationSettings, string>({
            queryFn: (companyId) => {
                return axios.get(`api/Notification/user-settings/${companyId}`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        return Promise.reject(new Error(response.data))
                    });
            },
            providesTags: () => [{ type: 'NotificationSettings' }]
        }),
        updateNotificationSettings: builder.mutation<NotificationSettingsKeys,{companyId: string, settings:NotificationSettingsKeys}>({
            queryFn: (payload) => {
                return axios.put(`api/Notification/user-settings/${payload.companyId}`, payload.settings)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                    });
            },
            invalidatesTags: () => [{ type: 'NotificationSettings' }]
        }),
        fetchNotificationChannels: builder.query<NotificationChannels, string>({
            queryFn: (companyId) => {
                return axios.get(`api/Notification/user-channels/${companyId}`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        return Promise.reject(new Error(response.data))
                    });
            },
            providesTags: () => [{ type: 'NotificationChannels' }]
        }),
        fetchUnreadCount: builder.query<{count: number}, void>({
            queryFn: () => {
                return axios.get(`api/Notification/count-unread`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        return Promise.reject(new Error(response.data))
                    });
            },
            providesTags: () => [{ type: 'Notifications' }]
        }),
        updateNotificationChannels: builder.mutation<NotificationChannels,{companyId: string, channels:NotificationChannels}>({
            queryFn: (payload) => {
                return axios.put(`api/Notification/user-channels/${payload.companyId}`, payload.channels)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                    });
            },
            invalidatesTags: () => [{ type: 'NotificationChannels' }]
        }),
        fetchNotifications: builder.query<AllNotifications, { companyId: string, page?: number, category?: string }>({
            queryFn: (data) => {
                const url = new URL(`${baseUrl}api/Notification/${data.companyId}?page=${data.page}`)
                if(data.category !== undefined && data.category !== 'All'){
                    url.searchParams.set('category', data.category)
                }
                if(data.page !== undefined){
                    url.searchParams.set('page', (data.page).toString())
                }
                const parseUrl = url.toString()
                const urlIndex = parseUrl.indexOf(`.com`) + 4
                const urlResult = parseUrl.substring(urlIndex)
                return axios.get(`${urlResult}`)
                    .then((response) => {
                        const hasUnread = response?.data?.result?.notifications?.findIndex((data:NotificationItem) => data.isRead === false)
                        const numberOfUnread = response?.data?.result?.notifications?.filter((data:NotificationItem) => data.isRead === false)
                        const result = {
                            ...response.data.result,
                            hasUnread: !(hasUnread === -1),
                            numberOfUnread: numberOfUnread.length
                        }
                        return {
                            data: result
                        }
                    })
                    .catch(({ response }) => {
                        return Promise.reject(new Error(response.data))
                    });
            },
            providesTags: () => [{ type: 'Notifications' }]
        }),
        markAllNotificationsAsRead: builder.mutation<void, {companyId: string | undefined}>({
            queryFn: (data) => {
                return axios.post(`api/Notification/mark-all-as-read`, data)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                    });
            },
            invalidatesTags: () => [{ type: 'Notifications' }]
        }),
        markNotificationAsRead: builder.mutation<NotificationChannels, { notificationId: string }>({
            queryFn: (data) => {
                return axios.post(`api/Notification/mark-as-read`, data)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                    });
            },
            invalidatesTags: () => [{ type: 'Notifications' }]
        }),
        saveDeviceToken: builder.mutation<void, { token: string, userId: string}>({
            queryFn: (data) => {
                return axios.post(`api/UserSession/${data.userId}`, { token: data.token})
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        return Promise.reject(new Error(response.data))
                    });
            }
        }),
    })
})


export const { 
    useFetchNotificationSettingsQuery, useFetchNotificationChannelsQuery,
    useUpdateNotificationChannelsMutation, useUpdateNotificationSettingsMutation,
    useFetchNotificationsQuery, useMarkAllNotificationsAsReadMutation,
    useMarkNotificationAsReadMutation, useSaveDeviceTokenMutation, useFetchUnreadCountQuery
} = notificationApi
