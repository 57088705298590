import { combineReducers } from '@reduxjs/toolkit';
import ApplicationReducer from './slices/ApplicationSlice'
import { lookuprefactorApi } from './slices/LookupSlice'
import { companyApi } from './slices/CompanySlice'
import { complianceApi } from './slices/ComplianceSlice'
import { screeningApi } from './slices/ScreeningSlice'
import { documentApi } from './slices/DocumentsSlice';
import { taskApi } from './slices/TasksSlice';
import { billingApi } from './slices/BillingSlice';
import { teamApi } from './slices/TeamSlice';
import { filingApi } from './slices/FilingsSlice';
import { clientonboardingApi } from './slices/ClientOnboardingSlice';
import { notificationApi } from './slices/NotificationsSlice';
import { obligationApi } from './slices/ObligationSlice';
import { userOnboardingApi } from './slices/UserOnboardingSlice';

export default combineReducers({
    application: ApplicationReducer,

    // RTK-Query
    companyApi: companyApi.reducer,
    complianceApi: complianceApi.reducer,
    screeningApi: screeningApi.reducer,
    documentApi: documentApi.reducer,
    teamApi: teamApi.reducer,
    taskApi: taskApi.reducer,
    billingApi: billingApi.reducer,
    filingApi: filingApi.reducer,
    lookuprefactorApi: lookuprefactorApi.reducer,
    clientonboardingApi: clientonboardingApi.reducer,
    notificationApi: notificationApi.reducer,
    obligationApi: obligationApi.reducer,
    userOnboardingApi: userOnboardingApi.reducer,
});
