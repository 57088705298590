import axios from "../../utils/AxiosConfig";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AmountOfSearchLeftResultType, GetAllMonitoredResult, SearchRequest } from "../../utils/typings/screening";
import { toast } from "react-toastify";


export const screeningApi = createApi({
    reducerPath: 'screeningApi',
    tagTypes: ['AllMonitoring', 'ScreeningStats', 'MonitoringStatus'],
    keepUnusedDataFor: 30 * (60 * 1000),
    refetchOnReconnect: true,
    baseQuery: fetchBaseQuery({ baseUrl: '/' }),
    endpoints: (builder) => ({
      getAllMonitoring: builder.query<GetAllMonitoredResult[], void>({
            queryFn: () => {
                return axios.get(`api/ComplyAdvantage/GetAllMonitoredSearchForUser`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
						return Promise.reject(new Error(response.data))
					});
            },
            providesTags: () => [{ type: 'AllMonitoring' }]
        }),
        getScreeningStats: builder.query<AmountOfSearchLeftResultType, void>({
            queryFn: () => {
                return axios.get(`api/ComplyAdvantage/GetAmountOfSearchLeft`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
						return Promise.reject(new Error(response.data))
					});
            },
            providesTags: () => [{ type: 'ScreeningStats' }]
        }),
        searchScreening: builder.mutation<any, SearchRequest>({
            queryFn: (data) => {
                return axios.post('api/ComplyAdvantage/Search', data)
                    .then((response) => {
                        return {
                            data: response.data.result.content.data
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                });
            },
            invalidatesTags: ['AllMonitoring', 'ScreeningStats', 'MonitoringStatus']
        }),
        getMonitoringStatus: builder.query<boolean, string>({
            queryFn: (data) => {
                return axios.get("api/ComplyAdvantage/GetSearchMonitor", {params : {searchIdOrSearchRef: data}})
                    .then((response) => {
                        return {
                            data: response.data.result.content?.is_monitored
                        }
                    })
                    .catch(({ response }) => {
						return Promise.reject(new Error(response.data))
					});
            },
            providesTags: () => [{ type: 'MonitoringStatus' }]
        }),
        updateMonitoringStatus: builder.mutation<any, { id: string; status: boolean; }>({
            queryFn: (data) => {
                return axios.patch("api/ComplyAdvantage/UpdateSearchMonitor", {
                        searchIdOrSearchRef: data.id,
                        is_Monitored: data.status,
                    })
                    .then((response) => {
                        return {
                            data: response.data.result?.content?.is_monitored
                        }
                    })
                    .catch(({ response }) => {
						toast.error(response.data.message)
						return Promise.reject(new Error(response.data))
					});
            },
            invalidatesTags: ['AllMonitoring', 'ScreeningStats' ]
        }),
    })
})

export const { 
    useGetAllMonitoringQuery, useGetScreeningStatsQuery, useSearchScreeningMutation, 
    useGetMonitoringStatusQuery , useUpdateMonitoringStatusMutation
} = screeningApi

