import { createSlice } from '@reduxjs/toolkit';


type ApplicationState = {
    sidebarCollapsed: boolean
};

const initialState: ApplicationState = {
    sidebarCollapsed: true
};

const ApplicationSlice = createSlice({
    name: 'application',
    initialState,
    reducers: {
        toggleSideBarCollapse: (state) => {
            state.sidebarCollapsed = !state.sidebarCollapsed
        },
    },
});

export default ApplicationSlice.reducer;
export const {toggleSideBarCollapse} = ApplicationSlice.actions


