import React,{useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import TravelGlobeImage from '../../assets/svgs/card-img-two.webp';
import WomanSideViewImage from '../../assets/svgs/card-img-three.webp';
import WomanStandingImage from '../../assets/svgs/card-img-one.webp';
import HeroImage from '../../assets/svgs/hero-mockup-image.webp';
import ScreeningImage from '../../assets/svgs/screening-mockup.webp';
import PenaltiesImage from '../../assets/svgs/penalties-mockup.webp';
import TrackingImage from '../../assets/svgs/tracking-mockup.webp';
import DeadlineImage from '../../assets/svgs/deadline-image-icon.webp';
import DiscoveryImage from '../../assets/svgs/discovery-dashboard-icon.webp';
import DocumentsImage from '../../assets/svgs/documents-mockup.webp';
import MorImage from '../../assets/svgs/mor-assets/autocomply-mor.webp';
import { faqData, complianceObligations } from '../../utils/data/landingpage';
import { FaqItem } from '../../utils/typings/landingpage';

const TwitterIcon = React.lazy(() => import('../../assets/svgs/twitter-icon.svg').then(module => ({ default: module.ReactComponent })));
const FacebookIcon = React.lazy(() => import('../../assets/svgs/facebook-icon.svg').then(module => ({ default: module.ReactComponent })));
const LinkedInIcon = React.lazy(() => import('../../assets/svgs/linked-in-icon.svg').then(module => ({ default: module.ReactComponent })));
const LogoWhite = React.lazy(() => import('../../assets/svgs/auto-comply-logo-icon.svg').then(module => ({ default: module.ReactComponent })));


const LandingPage = () => {

    const navigate = useNavigate()
    
    const handleLoginProceed = async () => {
        navigate('/login')
    }

    const [faqs, setFaqs] = useState<FaqItem[]>(faqData);
  
    const handleFaqClick = (index: number) => {
        const updatedFaqs = [...faqs];
        updatedFaqs[index].isOpen = !updatedFaqs[index].isOpen;
        setFaqs(updatedFaqs);
    };
    
    return ( 
        <div className="landing-page-wrapper">
            <div className="hero">
            
            <div className="navbar">
                <div className="logo">
                    {<LogoWhite width={180} height={100}></LogoWhite> }
                </div>
                <div className="btn-container">
                    <button onClick={()=>handleLoginProceed()}>
                        Get Started
                    </button>
                </div>
            </div>
            <div className="hero-content">
                <div className="hero-content-heading">
                    <p className='font-bold'>
                        Simplified Compliance Solution <br/> For Your Team
                    </p>
                    <div className='hero-subtext'>
                        AutoComply helps global compliance teams discover, automate and collaborate on filing tax, regulatory, license and corporate governance obligations.
                    </div>
                </div>
                <div className="btn-container" style={{ marginBottom:'30px',}}>
                    <button onClick={()=>handleLoginProceed()}>
                        Get Started Now
                    </button>
                </div>
                <div className="hero-image-container">
                    <img src={HeroImage} alt="My Image" />
                </div>
            </div>
            </div>
            <div className='autocomply-user-section'>
                <div className="user-heading">
                    <span>We built a tool to help compliance teams collaborate and give real-time insights to boards</span>
                    <h2>Who can use AutoComply</h2>
                    <div className="cards">
                        <div className="card">
                            <img src={TravelGlobeImage} alt="My Image" />
                            <div className="card-details">
                                <h1>Multinationals</h1>
                                <p>
                                For organizations operating in multiple countries, AutoComply keeps you abreast off your taxes, legal obligations, deadlines and potential risk exposure in those markets.
                                </p>
                                <span onClick={()=>handleLoginProceed()}>Get started</span>
                            </div>
                        </div>
                        <div className="card">
                            <img src={WomanSideViewImage} alt="My Image" />
                            <div className="card-details">
                                <h1>Financial Companies</h1>
                                <p>
                                    From daily returns to quarterly audits, AutoComply simplifies the obligation stack for compliance teams in the financial industry. We help you track returns to your regulator, collaborate with team members and keep to deadlines.
                                </p>
                                <button onClick={()=>handleLoginProceed()}>Get started</button>
                            </div>
                        </div>
                        <div className="card">
                            <img src={WomanStandingImage} alt="My Image" />
                            <div className="card-details">
                                <h1>Startups</h1>
                                <p>
                                    Visualize your expansion goals with AutoComply. Our obligation discovery dashboard
                                    lets you figure out hiring requirements, new taxes,
                                    licenses and regulatory obligations as you grow across states or expand globally.
                                </p>
                                <button onClick={()=>handleLoginProceed()}>Get started</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="autocomply-services-container">
                <div className="title-container">
                    <p>Reduce your exposure to risks and fines</p>
                    <h2>AutoComply ensures your business always stays on the right side of the law.</h2>
                </div>

                <div className="container">
                    <div className="grid">
                        {complianceObligations.map((obligation: any) => (
                        <div className={`card  ${obligation.containerType === 'text-first' ? 'text-first' : 'image-first'}`} key={obligation.name}>
                           <div className="card-details">
                                <h2 className="name">{obligation.name}</h2>
                                <p className="description">{obligation.description}</p>
                                
                           </div>
                           <div className="service-image">
                             {obligation.image === 'discovery' &&  <img src={DiscoveryImage} alt="" />}
                             {obligation.image === 'deadline' &&  <img src={DeadlineImage} alt="" />}
                             {obligation.image === 'tracking' &&  <img src={TrackingImage} alt="" />}
                             {obligation.image === 'documents' &&  <img src={DocumentsImage} alt="" />}
                             {obligation.image === 'penalties' &&  <img src={PenaltiesImage} alt="" />}
                             {obligation.image === 'screening' &&  <img src={ScreeningImage} alt="" />}
                           </div>
                        </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="bg-[#3A2C49]">
                <p>See AutoComply live in action <s></s></p>
                <div className=' w-full flex flex-col md:flex-row items-center py-60'>
                   <div className='w-full  md:w-1/2 md:px-[6rem] flex-wrap px-[10px]'>
                        <div className='bg-[#473658] p-[6rem] w-full h-full'>
                            <img src={MorImage} alt="" />
                        </div>
                   </div>
                   <div className='w-full  md:w-1/2 md:px-[6rem] md:mt-0 mt-20 flex-wrap px-[10px]'>
                        <p className="text-[16px]  text-[#FFF2E8] ">
                            MERCHANT OF RECORDS (MoR)
                        </p>
                        <p className="md:text-[40px] text-[40px] text-[#FFF2E8]  font-[400] md:leading-[50px] leading-[40px] mb-8">
                            All your global sales tax and compliance handled
                        </p>
                        <p className=' max-w-[90%] text-left md:text-[25px] text-[16px] mb-16 text-white'>
                            We serve as your trusted global Merchant of Record (MoR), providing comprehensive infrastructure and solutions 
                            that enables your business reach a wider market faster.
                        </p>

                        <Link to='/MoR' className='px-10 py-4 bg-orange  rounded text-white'>
                            Get Started
                        </Link>
                   </div>
                </div>
                {/* <div className="iframe-container" style={{ position:'relative'}}>
                    <iframe className='absolute top-0 left-0 w-full h-full'
                     src="https://nbukssa.blob.core.windows.net/autocomplydemovideo/Auto_comply_demo.mp4" 
                      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                      title="Auto Comply Demo Video"
                      allowFullScreen
                     >
                    </iframe>
                </div> */}
            </div>
            {/* <div className="why-norebase-section"> 
                <p>Why AutoComply is the right choice</p>
                <h2>We handle your business effectively</h2>
                <div className="list-container">
                    <div className="card">
                        <div className="icon">
                            <TransparencyIcon width={80} height={80}/>
                        </div>
                        <div className="details">
                            <h3>Transparency all the way</h3>
                            <span>You get comprehensive support from business registration to ongoing compliance management.</span>
                        </div>
                    </div>
                    <div className="card">
                        <div className="icon">
                            <SpeedIcon width={80} height={80} />
                        </div>
                        <div className="details">
                            <h3>Fast and effective filing</h3>
                            <span>Our incorporation is the quickest in most countries. Launch and start running your business within 5-10 days.</span>
                        </div>
                    </div>
                    <div className="card">
                        <div className="icon">
                            <PricingIcon width={80} height={80} />
                        </div>
                        <div className="details">
                            <h3>Fair pricing</h3>
                            <span>We offer the most affordable prices by standardising them to align with your business needs.</span>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="dark-section" id='faq'>
                <div className="dark-section-heading">
                    <span>GET TO KNOW MORE</span>
                    <h2>Frequently asked question</h2>
                </div>
                <div className="dark-section-questions" >
                    <div className="faq">
                        <div className="accordion flex flex-col">
                            {faqs.map((faq, index) => (
                            <button key={faq.question} className='item cursor-pointer text-left' onClick={() => handleFaqClick(index)}>
                                <div className="accordion-title">
                                    <div className="count-block">
                                        <h3  >{faq.question}</h3>
                                    </div>
                                    <div className='icon'>{faq.isOpen ? "-" : "+"}</div>
                                </div>
                               {faq.isOpen === true && <div className={`content ${faq.isOpen ? "show" : ""}`}>
                                    <p className={`answer`}>
                                    {faq.answer}
                                    </p>
                                </div>}
                            </button>
                            ))}
                        </div>
                    </div>
                </div>
                
            </div>
            <div className="started-container">
                <div className="get-started-now">
                    <h2>Ensure your companies never miss an obligation.</h2>
                    <button onClick={()=>handleLoginProceed()}>
                        Get Started Now
                    </button>
                </div>
            </div>
            <div className="footer-container">
                    <div className="community-section">
                        <LogoWhite width={150} height={80}></LogoWhite> 
                    </div>
                    <div className=" links-section">
                        <div className="section company-section">
                            <p>Company</p>
                            <a href="https://norebase.com/about-us">About us</a>
                            <a href="https://blog.norebase.com/">Blog</a>
                            <a href="#faq">FAQs</a>
                        </div>
                        <div className="section legal-section">
                            <p>Legal</p>
                            <a href="https://norebase.com/terms">Terms</a>
                            <a href="https://norebase.com/privacy">Privacy</a>
                            <a href="https://norebase.com/refunds">Refunds</a>
                        </div>
                        <div className="section product-section">
                            <p>Products</p>
                            <a href="https://norebase.com/products/launch">Launch</a>
                            <a href="https://infra.norebase.com/">Norebase API</a>
                            <a href="https://docs.norebase.com/">API Documentation</a>
                        </div>
                        <div className="section contact-section">
                            <div className="social-media-links">
                                <a href="https://www.facebook.com/profile.php?id=100072023952939"><FacebookIcon /></a>
                                <a href="https://www.linkedin.com/company/norebase/"><LinkedInIcon /></a>
                                <a href="https://twitter.com/norebase"><TwitterIcon /></a>
                            </div>
                            <a href="mailto:hello@norebase.com">hello@norebase.com</a>
                            <a href="tel:+1 360-295-2698">Call +1 360-295-2698</a>
                            <a href="tel:+234-80-3977-7385">Call +234-80-3977-7385</a>
                        </div>
                    </div>
                </div>
        </div>
     );
}
 
export default LandingPage;