// MSAL imports
import { PublicClientApplication, EventType } from "@azure/msal-browser";
// import { useHistory } from "react-router-dom";
import history from "../@history";
//import { useNavigate } from "react-router-dom";
// import { msalConfig, loginRequest } from '../azureAdB2cServiceConfig';

import { msalConfig, loginRequest } from './azureAdB2cServiceConfig';

//let navigate = useNavigate()
export const msalInstance = new PublicClientApplication(msalConfig);
// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event:any) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    
    const account = event.payload.account;
    localStorage.removeItem('access_token');
    // console.log('from EventType.LOGIN_SUCCESS', localStorage.getItem('access_token'))
    msalInstance.setActiveAccount(account);
    //console.log(event.payload.idTokenClaims)
    if(event.payload.idTokenClaims.isSignUp){
      history.push({
        pathname: '/auth/signup-success',
        state: { redirectUrl: '/auth/signup-success' },
      });
    }
  }
  else if (event.eventType === EventType.LOGIN_FAILURE) {
    if (event.error.errorMessage.indexOf("AADB2C90091") > -1) {
      msalInstance.logoutRedirect(loginRequest);
    }
  }
  else if(event.eventType==EventType.ACQUIRE_TOKEN_FAILURE && event.error.name==='InteractionRequiredAuthError'){
    msalInstance.logoutRedirect();
  }
});