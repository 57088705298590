import axios, { OperationsAxiosInstance } from '../../utils/AxiosConfig';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { toast } from 'react-toastify';
import { CompanyFilings, OfflineFilingPayload, OutputObject, CurrentFilings } from '../../utils/typings/compliance';


export const filingApi = createApi({
    reducerPath: 'filingApi',
    tagTypes: ['CurrentFilings', 'PreviousFilings', 'CompanyFilings'],
    keepUnusedDataFor: 30 * (60 * 1000),
    refetchOnReconnect: true,
    baseQuery: fetchBaseQuery({ baseUrl: '/' }),
    endpoints: (builder) => ({
        postFilings: builder.mutation<void, { filingResponses: OutputObject[], companyId: string, complianceId: string }>({
            queryFn: (data) => {
                return axios.post(`api/CompanyFilings`, data)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
						toast.error(response.data.message)
						return Promise.reject(new Error(response.data))
					});
            },
        }),
        fetchCurrentFilings: builder.query<CurrentFilings, { companyId: string, complianceId: string }>({
            queryFn: (data) => {
                return axios.get(`api/CompanyFilings/current/${data.companyId}/${data.complianceId}`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
						return Promise.reject(new Error(response.data))
					});
            },
            providesTags: () => [{ type: 'CurrentFilings' }]
        }),
        fetchPreviousFilings: builder.query<{ complianceName: string, id: string }[], { companyId: string, complianceId: string }>({
            queryFn: (data) => {
                return axios.get(`api/CompanyFilings/previous/${data.companyId}/${data.complianceId}`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
						return Promise.reject(new Error(response.data))
					});
            },
            providesTags: () => [{ type: 'PreviousFilings' }]
        }),
        fetchCompanyFilings: builder.query<CompanyFilings, { page: number, pageSize: number }>({
            queryFn: (data) => {
                return axios.get(`api/CompanyFilings/get-paged-requests/${data.page}/${data.pageSize}/{}`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
						return Promise.reject(new Error(response.data))
					});
            },
            providesTags: () => [{ type: 'CompanyFilings' }]
        }),
        uploadDocument: builder.mutation<string, FormData>({
            queryFn: (data) => {
                const uploadConfig = {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    },
                }
                return OperationsAxiosInstance.put(`Document/upload-document`, data, uploadConfig)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
						toast.error(response.data.message)
						return Promise.reject(new Error(response.data))
					});
            },
        }),
        postFilingPayment: builder.mutation<void, { cardId: string, companyId: string, complianceId: string }>({
            queryFn: (data) => {
                return axios.post(`api/CompanyFilings/make-payment`, data)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
						toast.error(response.data.message)
						return Promise.reject(new Error(response.data))
					});
            },
        }),
        postFilingAddNewCard: builder.mutation<{ paymentUrl: Location | (string & Location) }, { portalUrl: string, companyId: string, complianceId: string }>({
            queryFn: (data) => {
                return axios.post(`api/CompanyFilings/add-filing-payment-method`, data)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
						toast.error(response.data.message)
						return Promise.reject(new Error(response.data))
					});
            },
        }),
        postOfflineFiling: builder.mutation<void, OfflineFilingPayload>({
            queryFn: (data) => {
                return axios.post(`api/CompanyFilings/mark-as-filed`,data)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
						toast.error(response.data.message)
						return Promise.reject(new Error(response.data))
					});
            },
        }),
    })
})


export const { 
    usePostFilingsMutation, useFetchCurrentFilingsQuery, useFetchPreviousFilingsQuery,
    useFetchCompanyFilingsQuery, useUploadDocumentMutation, usePostFilingPaymentMutation,
    usePostFilingAddNewCardMutation, usePostOfflineFilingMutation, 
} = filingApi