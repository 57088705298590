import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react'
import reducer from './Reducer';
import { companyApi } from './slices/CompanySlice';
import { complianceApi } from './slices/ComplianceSlice';
import { screeningApi } from './slices/ScreeningSlice';
import { documentApi } from './slices/DocumentsSlice';
import { teamApi } from './slices/TeamSlice';
import { taskApi } from './slices/TasksSlice';
import { billingApi } from './slices/BillingSlice';
import { filingApi } from './slices/FilingsSlice';
import { lookuprefactorApi } from './slices/LookupSlice';
import { clientonboardingApi } from './slices/ClientOnboardingSlice';
import { notificationApi } from './slices/NotificationsSlice';
import { obligationApi } from './slices/ObligationSlice';
import { userOnboardingApi } from './slices/UserOnboardingSlice';


export const store = configureStore({
	reducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware()
		.concat([
			companyApi.middleware, complianceApi.middleware, teamApi.middleware,
			taskApi.middleware, billingApi.middleware, filingApi.middleware,
			lookuprefactorApi.middleware, screeningApi.middleware, documentApi.middleware,
			clientonboardingApi.middleware, notificationApi.middleware, obligationApi.middleware,
            userOnboardingApi.middleware,
		])
	// middleware: [
	// 	//...getDefaultMiddleware(),
	// 	// requests,
	// 	// middleWares,
	// 	thunkMiddleware,
	// ],
});
setupListeners(store.dispatch)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch