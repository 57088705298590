import { createContext } from "react";
import { MemberAccessList, UserProfile } from "./typings/settings";

type Context = {
    setEditAccessList: (data:MemberAccessList[]) => void,
	userEditAccessList: MemberAccessList[],
    userProfile: UserProfile,
    setUserProfile: (data: UserProfile) => void
}
export const AppContext = createContext<Context>({
    setEditAccessList: () => '',
    userEditAccessList: [],
    setUserProfile: () => '',
    userProfile: {
        userType: '',
        userId: '',
        firstName: '',
        lastName: '',
        emailAddress: '',
        username: '',
        phoneNumber: '',
        userTypeId: 0,
        permissions: [],
        isAdmin: false,
        hasCurrentSubscription: false,
        profilePictureUrl: '',
    }
})