import axios from '../../utils/AxiosConfig';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { toast } from 'react-toastify';
import { GuideFlowType } from '../../utils/typings/clientonboarding';


export const userOnboardingApi = createApi({
    reducerPath: 'userOnboardingApi',
    tagTypes: ['Guides'],
    keepUnusedDataFor: 30 * (60 * 1000),
    refetchOnReconnect: true,
    baseQuery: fetchBaseQuery({ baseUrl: '/' }),
    endpoints: (builder) => ({
        postUserOnboardingChecklist: builder.mutation<GuideFlowType, {companyId: string, payload: any}>({
            queryFn: (data) => {
                return axios.post(`api/UserOnboardingChecklist/${data.companyId}`, data.payload)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
						toast.error(response.data.message)
						return Promise.reject(new Error(response.data))
					});
            },
            invalidatesTags: () => [{ type: 'Guides' }]
        }),
        fetchUserOnboardingChecklist: builder.query<GuideFlowType, string>({
            queryFn: (companyId) => {
                return axios.get(`api/UserOnboardingChecklist/${companyId}`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
						return Promise.reject(new Error(response.data))
					});
            },
            providesTags: () => [{ type: 'Guides' }]
        }),
        
    })
})


export const { 
    usePostUserOnboardingChecklistMutation, useFetchUserOnboardingChecklistQuery , 
} = userOnboardingApi


