import * as signalR from '@microsoft/signalr'

export class SignalRService {
    private connection: signalR.HubConnection

    private numberOfReconnects = 0
    private maxNumberOfReconnects = 10

    constructor (url: string) {
        const token = localStorage.getItem('access_token')

        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(url, {
                // skipNegotiation: true,
                // transport: signalR.HttpTransportType.WebSockets, // sockets is currently down. debuging why
                withCredentials: true,
                accessTokenFactory: () => `${token}`
            })
            .configureLogging(signalR.LogLevel.Information)
            .withAutomaticReconnect({
                nextRetryDelayInMilliseconds: (retryContext) => {
                    if (retryContext.elapsedMilliseconds < 60000) {
                        // if we've been reconnecting for less than 60 seconds so far,
                        // wait between 0 and 10 seconds before the next reconnect attempt
                        return Math.random() * 10000
                    } else {
                        // we've been reconnecting for more than 60 seconds so far, stop reconnecting
                        return null
                    }
                }
            })
            .build()

        this.startConnection()

        this.connection.onreconnecting(error => {
            console.warn('SignalR connection lost due to error. Reconnecting...', error)
        })

        this.connection.onreconnected(connectionId => {
            if (connectionId) {
                sessionStorage.setItem('socketConnectionId', connectionId)
                const companyId = sessionStorage.getItem('activeCompanyId')
                if (companyId) {
                    this.connection.invoke('SubscribeCompany', companyId, connectionId)
                }
            }
        })

        this.connection.onclose(error => {
            console.error('SignalR connection closed. Error:', error)
            sessionStorage.removeItem('socketConnectionId')

            this.startConnection()
        })
    }

    public async startConnection () {
        try {
            await this.connection.start()
            console.log('SignalR connection established.')

            this.connection.on('ReceiveConnectionId', (socketConnectionId: string) => {
                const companyId = sessionStorage.getItem('activeCompanyId')
                if (companyId) {
                    this.connection.invoke('SubscribeCompany', companyId, socketConnectionId)
                        .then(() => {
                            sessionStorage.setItem('socketConnectionId', socketConnectionId)
                        })
                        .catch(err => console.warn('SignalR SubscribeCompany Error: ', err))
                }
            })
        } catch (err) {
            console.warn('Error establishing SignalR connection. Retrying...', err)
            if (this.numberOfReconnects < this.maxNumberOfReconnects) {
                this.numberOfReconnects++
                setTimeout(() => this.startConnection(), Math.random() * 10000)
            }
        }
    }

    public on (event: string, callback: (data: any) => void) {
        this.connection.on(event, callback)
    }

    public off (event: string, callback: (data: any) => void) {
        this.connection.off(event, callback)
    }

    public send (event: string, ...args: any[]) {
        if (this.connection.state === signalR.HubConnectionState.Connected) {
            this.connection
                .invoke(event, ...args)
                .catch(err => console.error('SignalR Send Error: ', err))
        } else {
            console.warn('SignalR connection is not extablished. Cannot send message.')
        }
    }

    public close () {
        this.connection
            .stop()
            .catch(err => console.error('SignalR Close Error: ', err))
    }
}