import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import axios from '../../utils/AxiosConfig';
import { toast } from 'react-toastify';
import { ClientDataType, KYCItem,  ProspectQueryType, ProspectsType, ReportType, UpdateKYCRequestType, WatchListType, statsType } from "../../utils/typings/clientonboarding";



export const clientonboardingApi = createApi({
    reducerPath: 'clientonboardingApi',
    tagTypes: ['Prospects','ProspectiveClient','Client'],
    keepUnusedDataFor: 30 * (60 * 1000),
    refetchOnReconnect: true,
    baseQuery: fetchBaseQuery({ baseUrl: '/' }),
    endpoints: (builder) => ({
        getProspects: builder.query<ProspectsType[], ProspectQueryType>({
            queryFn: (params) => {
                const url = `api/ClientOnboarding/${params.companyId}`;
                const queryParams = `?page=${params.page}&status=${params.status}&search=${params.search}&limit=${params.limit}&watchlisted=${params.watchlisted}`;
                return axios.get(url + queryParams)
                    .then((response) => {
                        return {
                            data: response.data.result.items
                        }
                    })
                    .catch(({ response }) => {
                        return Promise.reject(new Error(response.data))
                    });
            },
            providesTags: () => [{ type: 'Prospects' }]
        }),
        getOnboardingMetrics: builder.query<statsType, string >({
            queryFn: (companyId) => {
                return axios.get(`api/ClientOnboarding/${companyId}/stats`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        return Promise.reject(new Error(response.data))
                    });
            },
            providesTags: () => [{ type: 'Prospects' }]
        }),
        postProspectiveClient: builder.mutation<KYCItem, ClientDataType>({
            queryFn: (data) => {
                return axios.post('api/ClientOnboarding', data)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                    });
            },
            invalidatesTags: ['Prospects']
        }),
        postEditedClient: builder.mutation<KYCItem, ClientDataType>({
            queryFn: (data) => {
                return axios.patch('api/ClientOnboarding/edit', data)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                    });
            },
            invalidatesTags: ['Prospects']
        }),
        getClient: builder.query<KYCItem, {companyId: string, clientId: string}>({
            queryFn: (data) => {
                return axios.get(`api/ClientOnboarding/${data.companyId}/${data.clientId}`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        return Promise.reject(new Error(response.data))
                    });
            },
            providesTags: () => [{ type: 'Client' }]
        }),
        getClientInputDetails: builder.query<ProspectsType, {companyId: string, clientId: string}>({
            queryFn: (data) => {
                return axios.get(`api/ClientOnboarding/get-input/${data.companyId}/${data.clientId}`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        return Promise.reject(new Error(response.data))
                    });
            },
            providesTags: () => [{ type: 'Client' }]
        }),
        postWatchList: builder.mutation<void, WatchListType>({
            queryFn: (data) => {
                return axios.post('api/ClientOnboarding/watchlist', data)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                    });
            },
            invalidatesTags: ['Prospects', 'Client']
        }),
        postClientApproval: builder.mutation<void, UpdateKYCRequestType>({
            queryFn: (data) => {
                return axios.post('api/ClientOnboarding/approve', data)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                    });
            },
            invalidatesTags: ['Prospects', 'Client']
        }),
        postClientWebhook: builder.mutation<void, void>({
            queryFn: (data) => {
                return axios.post('api/ClientOnboarding/webhook')
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                    });
            },
            invalidatesTags: ['Prospects']
        }),
        getCompanyReports: builder.query<ReportType[], string>({
            queryFn: (companyId) => {
                return axios.get(`api/ClientOnboarding/reports/${companyId}`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        return Promise.reject(new Error(response.data))
                    });
            },
            providesTags: () => [{ type: 'Client' }]
        }),
        getClientReport: builder.query<ReportType[], {companyId: string, clientId: string}>({
            queryFn: (data) => {
                return axios.get(`api/ClientOnboarding/reports/${data.companyId}/${data.clientId}`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        return Promise.reject(new Error(response.data))
                    });
            },
            providesTags: () => [{ type: 'Client' }]
        }),
        postReportDownload: builder.mutation<void, {fileUrl: string,companyId: string }>({
            queryFn: (data) => {
                return axios.post(`api/ClientOnboarding/${data.companyId}/download`, {fileUrl: data.fileUrl})
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                    });
            },
            invalidatesTags: ['Prospects', 'Client']
        }),
    })
})

export const { 
    useGetProspectsQuery,
    usePostProspectiveClientMutation,
    usePostEditedClientMutation,
    useGetCompanyReportsQuery,
    useGetClientReportQuery,
    usePostReportDownloadMutation,
    usePostWatchListMutation,
    useGetClientQuery,
    useGetOnboardingMetricsQuery,
    useGetClientInputDetailsQuery,
    usePostClientWebhookMutation,
    usePostClientApprovalMutation,
} = clientonboardingApi
