export const NatureOfBusiness = [
  { name: 'Finance/Insurance/Real Estate', value: 'FinanceInsuranceRealEstate' },
  { name: 'Information Communication Technology (ICT)', value: 'ICT' },
  { name: 'Mining/Oil & Gas', value: 'MiningOilAndGas' },
  { name: 'Services', value: 'Services' },
  { name: 'Farming/Fisheries', value: 'FarmingAndFisheries' },
  { name: 'Health/Pharmacy', value: 'HealthAndPharmacy' },
  { name: 'Manufacturing', value: 'Manufacturing' },
  { name: 'Commerce', value: 'Commerce' },
  { name: 'Construction/Civil Engineering', value: 'ConstructionAndCivilEngineering' },
  { name: 'Transportation', value: 'Transportation' },
  { name: 'Paints and Varnishes', value: 'PaintsAndVanishes' },
  { name: 'Chemicals', value: 'Chemicals' },
  { name: 'Others', value: 'Others' },
];

export const NatureOfBusinessForBusinessNameRegistration = [
  { name: 'Services', value: 'Services' },
  { name: 'Farming/Fisheries', value: 'FarmingAndFisheries' },
  { name: 'Health/Pharmacy', value: 'HealthAndPharmacy' },
  { name: 'Manufacturing', value: 'Manufacturing' },
  { name: 'Commerce', value: 'Commerce' },
  { name: 'Construction/Civil Engineering', value: 'ConstructionAndCivilEngineering' },
  { name: 'Transportation', value: 'Transportation' },
  { name: 'Paints and Varnishes', value: 'PaintsAndVanishes' },
  { name: 'Chemicals', value: 'Chemicals' },
  { name: 'Others', value: 'Others' },
];

export const CountryCurrency = {
  Tanzania: 'TZS',
  Rawanda: 'RWF',
  Uganda: 'USh',
  Cameroon: 'XAF',
  Egypt: 'EGP',
  Zambia: 'ZMW',
  Zimbabwe: 'ZWD',
};

export const createGuid = () => {
  let d = new Date().getTime();
  if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
    d += performance.now(); // use high-precision timer if available
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const crypto = window.crypto || window.Crypto;
    const array = new Uint32Array(1);
    let randomValue = crypto.getRandomValues(array); // Compliant for security-sensitive use cases
    let r = (d + randomValue[0] * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const shippedCountries = ['nigeria', 'ghana', 'kenya', 'southafrica', 'usadelaware', 'senegal', 'civ', 'egypt', 'britishvirginislands'];

export const getTrackingId = () => {
  const domainName = window.location.hostname;
  switch (true) {
    case domainName.toLowerCase().indexOf("df") > -1:
      return {gaid:"UA-218478574-3", hotjarId:3099657, gtmId:'', awId:''};
    case domainName.toLowerCase().indexOf("dev") > -1:
    case domainName.toLowerCase().indexOf("localhost") > -1:
      return {gaid:"UA-218478574-1", hotjarId:3099657, gtmId:'', awId:''};
    default:
      return {gaid:"UA-214031198-1", hotjarId:3099920, gtmId:'GTM-PHJM4F8', awId:'AW-10927189749'};
  }
};

interface Roles {
  [key: string]: string
}

export const roleCodes: Roles = {
  Administrator: 'Administrator',
  TeamLeader: 'TeamLeader',
  TeamMember: 'TeamMember'
}

export const rolesDescription: Roles = {
  Administrator: 'Administrator',
  TeamLeader: 'Team Leader',
  TeamMember: 'Team Member'
}

// export const InputTypes = {
//   1:'select',
//   2:'radio',
//   3:'checkbox',
//   4:'text',
//   5:'File',
//   6:'number',
//   7:'date',
//   8:'multiselect',
//   9:'textbox',
//   10:'autocomplete'
// }