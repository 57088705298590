import axios,{ baseUrl } from '../../utils/AxiosConfig';
import unAuthenticatedAxios from 'axios'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { toast } from 'react-toastify';
import { complianceApi } from './ComplianceSlice';
import { ComplianceRequirement, TaskAssignPayload, Tasks, TasksExternal } from '../../utils/typings/compliance';
import { notificationApi } from './NotificationsSlice';
import { ObligationCardType, TaskQueryData, TaskSubmitPayload } from '../../utils/typings/tasks';


export const taskApi = createApi({
    reducerPath: 'taskApi',
    tagTypes: ['AssignedTasks', 'Tasks', 'TasksInternal'],
    keepUnusedDataFor: 30 * (60 * 1000),
    refetchOnReconnect: true,
    baseQuery: fetchBaseQuery({ baseUrl: '/' }),
    endpoints: (builder) => ({
        configureSteps: builder.mutation<ComplianceRequirement, TaskAssignPayload>({
            queryFn: (data) => {
                return axios.post('api/CompanyObligationStep/configure', data)
                    .then((response) => {
                        toast.success('Task assigned successfully')
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
						toast.error(response.data.message)
						return Promise.reject(new Error(response.data))
					});
            },
            async onQueryStarted(queryArg, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled
                    dispatch(complianceApi.util.invalidateTags(['ComplianceRequirements']))
                    dispatch(notificationApi.util.invalidateTags(['Notifications']))
                    
                } catch (err) {
                }
            },
            invalidatesTags: () => [{ type: 'Tasks' }]
        }),
        subtaskAssignment: builder.mutation<ComplianceRequirement, {taskId: string, payload: TaskAssignPayload}>({
            queryFn: (data) => {
                return axios.post(`api/CompanyObligationStep/tasks/${data.taskId}/assign`, data.payload)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
						toast.error(response.data.message)
						return Promise.reject(new Error(response.data))
					});
            },
            async onQueryStarted(queryArg, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled
                    dispatch(complianceApi.util.invalidateTags(['ComplianceRequirements']))
                    dispatch(notificationApi.util.invalidateTags(['Notifications']))
                } catch (err) {
                }
            },
            invalidatesTags: () => [{ type: 'Tasks' }]
        }),
        fetchAssignedTasks: builder.query<Tasks, string>({
            queryFn: (companyId) => {
                return axios.get(`api/Companies/${companyId}/assigned-tasks`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
						return Promise.reject(new Error(response.data))
					});
            },
            providesTags: () => [{ type: 'AssignedTasks' }]
        }),
        fetchMyTasksCount: builder.query<{id: number, name: string}, void>({
            queryFn: (companyId) => {
                return axios.get(`/api/Companies/count-my-tasks`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
						return Promise.reject(new Error(response.data))
					});
            },
            providesTags: () => [{ type: 'AssignedTasks' }]
        }),
        fetchAllTasks: builder.query<ObligationCardType[], TaskQueryData>({
            queryFn: (data) => {
                return axios.get(`api/Companies/${data.companyId}/all-tasks?search=${data.search}&category=${data.category}&status=${data.status}`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
						return Promise.reject(new Error(response.data))
					});
            },
            providesTags: () => [{ type: 'Tasks' }]
        }),
        fetchMyTasks: builder.query<ObligationCardType[], TaskQueryData>({
            queryFn: (data) => {
                return axios.get(`api/Companies/${data.companyId}/my-tasks?search=${data.search}&category=${data.category}&status=${data.status}`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
						return Promise.reject(new Error(response.data))
					});
            },
            providesTags: () => [{ type: 'Tasks' }]
        }),
        fetchTasks: builder.query<TasksExternal, { companyId: string, complianceId: string, stepId: string, emailAddress:string}>({
            queryFn: (data) => {
                return unAuthenticatedAxios.get(`${baseUrl}/api/CompanyObligationStep/fetch-tasks-external/${data.companyId}/${data.complianceId}/${data.stepId}?emailAddress=${data.emailAddress}`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
						return Promise.reject(new Error(response.data))
					});
            },
            providesTags: () => [{ type: 'Tasks' }]
        }),
        fetchTasksInternal: builder.query<TasksExternal, { companyId: string, complianceId: string, stepId: string}>({
            queryFn: (data) => {
                return axios.get(`${baseUrl}/api/CompanyObligationStep/fetch-tasks/${data.companyId}/${data.complianceId}/${data.stepId}`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
						return Promise.reject(new Error(response.data))
					});
            },
            providesTags: () => [{ type: 'Tasks' }]
        }),
        submitTask: builder.mutation<void, TaskSubmitPayload>({
            queryFn: (data) => {
                return axios.post(`api/CompanyObligationStep/submit-task-external`,data)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
						toast.error(response.data.message)
						return Promise.reject(new Error(response.data))
					});
            },
            async onQueryStarted(queryArg, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled
                    dispatch(complianceApi.util.invalidateTags(['ComplianceRequirements']))
                } catch (err) {
                }
            },
            invalidatesTags: () => [{ type: 'Tasks' }]
        }),
        submitInternalTask: builder.mutation<void, TaskSubmitPayload>({
            queryFn: (data) => {
                return axios.post(`api/CompanyObligationStep/submit-task`,data)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
						toast.error(response.data.message)
						return Promise.reject(new Error(response.data))
					});
            },
            async onQueryStarted(queryArg, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled
                    dispatch(complianceApi.util.invalidateTags(['ComplianceRequirements']))
                    dispatch(notificationApi.util.invalidateTags(['Notifications']))
                } catch (err) {
                }
            },
            invalidatesTags: () => [{ type: 'Tasks' }]
        }),
        submitComment: builder.mutation<void, { companyId: string, complianceId: string, comment: string, stepId: string}>({
            queryFn: (data) => {
                return axios.post(`api/CompanyObligationStep/comments/add`,data)
                    .then((response) => {
                        toast.success(response.data.result.message)
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
						toast.error(response.data.message)
						return Promise.reject(new Error(response.data))
					});
            },
            invalidatesTags: () => [{ type: 'Tasks' }]
        }),
        submitCommentExternal: builder.mutation<void, { companyId: string, complianceId: string, comment: string, stepId: string}>({
            queryFn: (data) => {
                return axios.post(`api/CompanyObligationStep/comments/add-external`,data)
                    .then((response) => {
                        toast.success(response.data.result.message)
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
						toast.error(response.data.message)
						return Promise.reject(new Error(response.data))
					});
            },
            invalidatesTags: () => [{ type: 'Tasks' }]
        }),
    })
})


export const { 
    useFetchAllTasksQuery,
    useFetchMyTasksQuery, useFetchMyTasksCountQuery,
    useConfigureStepsMutation, useSubtaskAssignmentMutation,
    useFetchAssignedTasksQuery , useFetchTasksQuery, useFetchTasksInternalQuery,
    useSubmitTaskMutation, useSubmitCommentMutation, useSubmitInternalTaskMutation,
    useSubmitCommentExternalMutation
} = taskApi


