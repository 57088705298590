import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";

const domainName = window.location.hostname;
let isEnable = false;

const ResolveUrl =()=>{
  switch(true){
    case domainName.toLowerCase().indexOf('df')>-1:
      isEnable = false;
      break;
    case domainName.toLowerCase().indexOf('dev')>-1:
    case domainName.toLowerCase().indexOf('localhost')>-1:
      isEnable = false;
      break;
    default:
      isEnable = true
  }
}
ResolveUrl()
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN_KEY,
  integrations: [new Sentry.BrowserTracing({
    tracePropagationTargets: ["localhost", /^https:\/\/prod-app-weu-vaultbackend\.azurewebsites\.net/],
  }), new Sentry.Replay()],
  debug: true,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  enabled: isEnable,
});



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
   </React.StrictMode> 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
