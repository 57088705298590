import axios from '../../utils/AxiosConfig';
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { toast } from 'react-toastify';
import { BillingDashboard, BillingDetails, PaymentMethod, SubscriptionPlans } from '../../utils/typings/billings';


export const billingApi = createApi({
    reducerPath: 'billingApi',
    tagTypes: ['BillingDashboard', 'BillingDetails', 'PaymentMethod'],
    keepUnusedDataFor: 30 * (60 * 1000),
    refetchOnReconnect: true,
    baseQuery: fetchBaseQuery({ baseUrl: '/' }),
    endpoints: (builder) => ({
        fetchBillingDashboard: builder.query<BillingDashboard, void>({
            queryFn: () => {
                return axios.get(`api/Billing/dashboard`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        return Promise.reject(new Error(response.data))
                    });
            },
            providesTags: () => [{ type: 'BillingDashboard' }]
        }),
        fetchBillingDetails: builder.query<BillingDetails, void>({
            queryFn: () => {
                return axios.get(`api/Billing/get-paged-requests/${1}/${50}/{}`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        return Promise.reject(new Error(response.data))
                    });
            },
            providesTags: () => [{ type: 'BillingDetails' }]
        }),
        fetchPaymentMethod: builder.query<PaymentMethod[], void>({
            queryFn: () => {
                return axios.get(`api/Billing/get-payment-methods`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        return Promise.reject(new Error(response.data))
                    });
            },
            providesTags: () => [{ type: 'PaymentMethod' }]
        }),
        addPaymentMethod: builder.mutation<{paymentUrl:string}, { portalUrl: string }>({
            queryFn: (url) => {
                return axios.post(`api/Billing/add-payment-method`, url)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                    });
            },
            invalidatesTags: () => [{ type: 'PaymentMethod' }]
        }),
        planSubscription: builder.mutation<{paymentUrl:string}, { portalUrl: string, planId:string, billingType: string }>({
            queryFn: (url) => {
                return axios.post(`api/Billing/plan-subscription`, url)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                    });
            },
        }),
        fetchInvoice: builder.query<{paymentUrl:string}, { portalUrl: string }>({
            queryFn: () => {
                return axios.get(`api/Subscription/get-payment-summary/`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        return Promise.reject(new Error(response.data))
                    });
            },
        }),
        fetchStripeInvoice: builder.query<{paymentUrl:string}, { portalUrl: string }>({
            queryFn: () => {
                return axios.get(`api/Subscription/get-payment-summary/stripe`)
                    .then((response) => {
                        return {
                            data: response.data.result.currentPaymentInfo?.stripePaymentUrl
                        }
                    })
                    .catch(({ response }) => {
                        return Promise.reject(new Error(response.data))
                    });
            },
        }),
        fetchSubscriptionPlans: builder.query<SubscriptionPlans[], void>({
            queryFn: () => {
                return axios.get(`api/Billing/subscription-plans`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        return Promise.reject(new Error(response.data))
                    });
            },
        }),
    })
})


export const { 
    useFetchBillingDashboardQuery, useFetchBillingDetailsQuery, useFetchPaymentMethodQuery,
    useAddPaymentMethodMutation, usePlanSubscriptionMutation, useFetchSubscriptionPlansQuery
} = billingApi
