import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import axios from '../../utils/AxiosConfig';
import { ComplianceRequirement, ComplianceSetupPayload, ComplianceType, ComplianceTypes } from '../../utils/typings/compliance';
import { toast } from 'react-toastify';



export const complianceApi = createApi({
    reducerPath: 'complianceApi',
    tagTypes: ['PriorityObligations', 'AllObligations', 'ComplianceRequirements'],
    keepUnusedDataFor: 30 * (60 * 1000),
    refetchOnReconnect: true,
    baseQuery: fetchBaseQuery({ baseUrl: '/' }),
    endpoints: (builder) => ({
        getPriorityObligations: builder.query<ComplianceType[], string>({
            queryFn: (companyId) => {
                return axios.get(`api/Companies/priority-obligations/${companyId}`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        return Promise.reject(new Error(response.data))
                    });
            },
            providesTags: () => [{ type: 'PriorityObligations' }]
        }),
        getAllObligations: builder.query<ComplianceTypes, {companyId: string, whereCondition: string}>({
            queryFn: (data) => {
                return axios.get(`api/Companies/v2/dashboard-compliance/${data.companyId}/${data.whereCondition}`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        return Promise.reject(new Error(response.data))
                    });
            },
            providesTags: () => [{ type: 'AllObligations' }]
        }),
        getComplianceRequirements: builder.query<ComplianceRequirement, {companyId: string, complianceId: string}>({
            queryFn: (data) => {
                return axios.get(`api/Companies/compliance-requirement/${data.companyId}/${data.complianceId}`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        return Promise.reject(new Error(response.data))
                    });
            },
            providesTags: () => [{ type: 'ComplianceRequirements' }]
        }),
        toggleComplianceTracking: builder.mutation<void, {companyId: string, complianceId: string}>({
            queryFn: (data) => {
                return axios.post('api/Companies/toggle-obligation-tracking', data)
                    .then((response) => {
                        toast.success(response.data.result.message)
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                    });
            },
            invalidatesTags: ['ComplianceRequirements']
        }),
        postComplianceSetup: builder.mutation<void, ComplianceSetupPayload>({
            queryFn: (data) => {
                return axios.post('api/Companies/compliance-setup', data)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                    });
            },
            invalidatesTags: ['ComplianceRequirements']
        }),
        postComplianceReminder: builder.mutation<void, {complianceId: string, companyId: string}>({
            queryFn: (data) => {
                return axios.post(`api/Companies/add-compliance-reminder`, data)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                    });
            },
            invalidatesTags: ['ComplianceRequirements']
        }),
        createCompliance: builder.mutation<void, {complianceId: string, companyId: string}>({
            queryFn: (data) => {
                return axios.post(`api/companies/add-compliance-item`, data)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        toast.error(response.data.message)
                        return Promise.reject(new Error(response.data))
                    });
            },
            invalidatesTags: ['AllObligations']
        }),
        getComplianceCategories: builder.query<{id: number, name: string}[], void>({
            queryFn: () => {
                return axios.get(`api/Countries/compliance-categories`)
                    .then((response) => {
                        return {
                            data: response.data.result
                        }
                    })
                    .catch(({ response }) => {
                        return Promise.reject(new Error(response.data))
                    });
            }
        }),
    })
})

export const { 
    useGetPriorityObligationsQuery, useGetAllObligationsQuery, 
    useToggleComplianceTrackingMutation, useGetComplianceRequirementsQuery,
    usePostComplianceSetupMutation, usePostComplianceReminderMutation,
    useCreateComplianceMutation, useGetComplianceCategoriesQuery
} = complianceApi
