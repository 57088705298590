import axios, { OperationsAxiosInstance } from "../../utils/AxiosConfig";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CompanyDocuments, CompanyFolder, FileDownload } from "../../utils/typings/documents";
import { toast } from "react-toastify";
import { TeamMemberType } from "../../utils/typings/settings";

interface DocumentArguments {
  companyId?: string
  folderId?: string;
  data?: any;
  searchString?: string
}

interface GetShareDetailsPayload {
  folderId?: string
  fileId?: string
  documentId?: string
  documentType?: string
  companyId?: string
  parentFolderId?: string
  payload?: any
}


export const documentApi = createApi({
	reducerPath: "documentApi", 
	tagTypes: ["ActiveCompanyDocuments", "SharedDocuments", 'SharedDocumentsDetails', "ActiveFolder", "QueryDocuments"],
	keepUnusedDataFor: 30 * (60 * 1000), 
	refetchOnReconnect: true, 
	baseQuery: fetchBaseQuery({ baseUrl: "/" }),
	endpoints: (builder) => ({
		getCompanyDocuments: builder.query<CompanyDocuments, string>({
			queryFn(activeCompany) {
				return axios
					.get(`api/Document/company/${activeCompany}/dashboard`)
					.then((response) => {
						return {
						data: response.data.result,
						};
					})
					.catch(({ response }) => {
						return Promise.reject(new Error(response.data))
					});
				},
			providesTags: () => [{ type: "ActiveCompanyDocuments" }],
		}),
		queryCompanyDocuments: builder.query<CompanyDocuments, DocumentArguments>({
			queryFn({ companyId, folderId, searchString }: DocumentArguments) {
				return axios
				.get(`api/Document/company/${companyId}/folders/${folderId}/search/${searchString}`)
				.then((response) => {
					return {
						data: response.data.result,
					};
				})
				.catch(({ response }) => {
					return Promise.reject(new Error(response.data))
				});
			},
			providesTags: () => [{ type: "QueryDocuments" }],
		}),
		getSingleFolder: builder.query<CompanyDocuments, DocumentArguments>({
			queryFn({ companyId, folderId }: DocumentArguments) {
				return axios
				.get(`api/Document/company/${companyId}/folders/${folderId}`)
				.then((response) => {
					return {
					data: response.data.result,
					};
				})
				.catch(({ response }) => {
					return Promise.reject(new Error(response.data))
				});
			},
			providesTags: () => [{ type: "ActiveFolder" }],
		}),
		addFile: builder.mutation<any, GetShareDetailsPayload>({
			queryFn({ companyId, folderId, payload }: GetShareDetailsPayload) {
				return axios
				.post(
					`api/Document/company/${companyId}/folders/${folderId}/files`,
					payload
				)
				.then((response) => {
					return {
					data: response.data.result,
					};
				})
				.catch(({ response }) => {
					toast.error(response.data.message)
					return Promise.reject(new Error(response.data))
				});
			},
			invalidatesTags: [{ type: "ActiveCompanyDocuments" }, { type: "ActiveFolder" }, { type: "QueryDocuments" }],
		}),
		createFolder: builder.mutation<CompanyFolder, GetShareDetailsPayload>({
			queryFn({ companyId, parentFolderId, payload }: GetShareDetailsPayload) {
				return axios.post(
					`api/Document/company/${companyId}/folders/${parentFolderId}`,
					payload
				)
				.then((response) => {
					return {
					data: response.data.result
					}
				})
				.catch(({ response }) => {
					toast.error(response.data.message)
					return Promise.reject(new Error(response.data))
				});
			},
			invalidatesTags: [{ type: "ActiveCompanyDocuments" }, { type: "ActiveFolder" }, { type: "QueryDocuments" }],
		}),
		deleteFolder: builder.mutation<{ message: string }, GetShareDetailsPayload>({
			queryFn({ companyId, folderId }: GetShareDetailsPayload) {
				return axios
				.delete(`api/Document/company/${companyId}/folders/${folderId}`)
				.then((response) => {
					return {
					data: response.data.result
					}
				})
				.catch(({ response }) => {
					toast.error(response.data.message)
					return Promise.reject(new Error(response.data))
				});
			},
			invalidatesTags: [{ type: "ActiveCompanyDocuments" }, { type: "ActiveFolder" }, { type: "QueryDocuments" }],
		}),
		deleteFile: builder.mutation<any, GetShareDetailsPayload>({
			queryFn({ companyId, parentFolderId, fileId }: GetShareDetailsPayload) {
				return axios
				.delete(
					`api/Document/company/${companyId}/folders/${parentFolderId}/files/${fileId}`
				)
				.then((response) => {
					return {
					data: response.data.result
					}
				})
				.catch(({ response }) => {
					toast.error(response.data.message)
					return Promise.reject(new Error(response.data))
				});
			},
			invalidatesTags: [{ type: "ActiveCompanyDocuments" }, { type: "ActiveFolder" }, { type: "QueryDocuments" }],
		}),
		downloadFile: builder.mutation<FileDownload, { url: string }>({
			queryFn(data) {
				return OperationsAxiosInstance.post(`Document/download-document`, data)
				.then((response) => {
					return {
					data: response.data.result
					}
				})
				.catch(({ response }) => {
					toast.error(response.data.message)
					return Promise.reject(new Error(response.data))
				});
			},
		}),
		renameFolder: builder.mutation<any, GetShareDetailsPayload>({
			queryFn({ companyId, folderId, payload }: GetShareDetailsPayload) {
				return axios.post(`api/Document/company/${companyId}/folders/${folderId}/rename`, payload)
				.then((response) => {
					return {
					data: response.data.result
					}
				})
				.catch(({ response }) => {
					toast.error(response.data.message)
					return Promise.reject(new Error(response.data))
				});
			},
			invalidatesTags: [{ type: "ActiveCompanyDocuments" }, { type: "ActiveFolder" }, { type: "QueryDocuments" }, { type: "SharedDocuments" }],
		}),
		getSharedDocuments: builder.query<any, void>({
			queryFn() {
				return axios
				.get(`api/Document/files/shared-with-me`)
				.then((response) => {
					return {
					data: response.data.result,
					};
				})
				.catch(({ response }) => {
					return Promise.reject(new Error(response.data))
				});
			},
			providesTags: () => [{ type: "SharedDocuments" }],
		}),
		getShareDetails: builder.query<TeamMemberType[], GetShareDetailsPayload>({
			queryFn({ documentId, documentType }: GetShareDetailsPayload) {
				if (documentType === 'folder') {
					return axios.get(`api/Document/folders/${documentId}/shared-details`)
						.then((response) => {
							return {
								data: response.data.result,
							};
						})
						.catch(({ response }) => {
							return Promise.reject(new Error(response.data))
						});
				} 
				else {
					return axios.get(`api/Document/files/${documentId}/shared-details`)
						.then((response) => {
							return {
								data: response.data.result,
							};
						})
						.catch(({ response }) => {
							return Promise.reject(new Error(response.data))
						});
					}
				},
			providesTags: () => [{ type: "SharedDocumentsDetails" }],
		}),
		shareFile: builder.mutation<any, GetShareDetailsPayload>({
			queryFn({ companyId, folderId, fileId, payload }: GetShareDetailsPayload) {
				return axios.post(`api/Document/company/${companyId}/folders/${folderId}/files/${fileId}/share`, payload)
				.then((response) => {
					return {
						data: response.data.result
					}
				})
				.catch(({ response }) => {
					toast.error(response.data.message)
					return Promise.reject(new Error(response.data))
				});
			},
			invalidatesTags: [{ type: "SharedDocumentsDetails" }],
		}),
		shareFolder: builder.mutation<any, any>({
			queryFn({ companyId, folderId, payload }: GetShareDetailsPayload) {
				return axios.post(`api/Document/company/${companyId}/folders/${folderId}/share`, payload)
				.then((response) => {
					return {
					data: response.data.result
					}
				})
				.catch(({ response }) => {
					toast.error(response.data.message)
					return Promise.reject(new Error(response.data))
				});
			},
			invalidatesTags: [{ type: "SharedDocumentsDetails" }],
    	}),
  	}),
});

export const {
  useGetCompanyDocumentsQuery,
  useQueryCompanyDocumentsQuery,
  useGetSingleFolderQuery,
  useAddFileMutation,
  useCreateFolderMutation,
  useDeleteFolderMutation,
  useRenameFolderMutation,
  useDeleteFileMutation,
  useDownloadFileMutation,
  useGetShareDetailsQuery,
  useShareFileMutation,
  useShareFolderMutation,
  useGetSharedDocumentsQuery
} = documentApi;
