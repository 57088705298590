
import { ObligationType } from "../typings/landingpage";

export const complianceObligations:ObligationType[] = [
    {
      name: "Effortless Obligation Discovery. ",
      description: "Ever been surprised by an obligation in a new country? AutoComply creates a real-time list of all your obligations and keeps that list up to date as obligations change.",
      image: 'discovery',
      containerType: 'image-first'
    },
    {
        name: "Never Miss a Deadline.",
        description: "Set up obligation reminders via email, Slack, or Microsoft Teams to stay on top of due dates for you and your team.",
        image: 'deadline',
        containerType: 'text-first'
    },
    {
      name: "Seamless Compliance Tracking.",
      description: "Manage obligations effortlessly across multiple companies and assign tasks to team members from our user-friendly dashboard.",
      image: 'tracking',
      containerType: 'image-first'
    },
    {
      name: "Document Management System.",
      description: "Auto-archived obligation documents allow convenient retrieval by your team or external collaborators with permission controls and support for custom folders",
      image: 'documents',
      containerType: 'text-first'
    },
    {
    name: "Stay Informed. Avoid Penalties.",
    description: "AutoComply gives you real-time insights into each company's compliance health with reports and risk exposure in the jurisdictions you operate in.",
    image: 'penalties',
    containerType: 'image-first'
    },
    {
      name: "Perform Customer Screenings.",
      description: "Perform PEP and sanction list screenings with real-time monitoring for subjects. AutoComply brings all your compliance tasks into one collaborative tool.",
      image: 'screening',
      containerType: 'text-first'
    },
]

export const faqData = [
    {
      question: "Does Autocomply help me file my compliance?",
      answer: "Autocomply helps facilitate collaboration between teams that are required to gather data and file compliance obligations. Regulatory forms can be split into different subtasks based on the department required to prepare or review the data to be submitted. Completion of the subtasks means that the form to be submitted to the regulator is complete and available for downloading. If you require assistance to file with the regulator, our team is available to provide guidance and support in our supported countries.",
      isOpen: false,
      tag: 'dashboard'
    },
    {
      question: "Do I get reminders and alerts on missed/due compliance obligations?",
      answer: "Once you subscribe to Autocomply, you gain access to a list of obligations applicable to your business in our fully supported countries. You can also onboard your own custom obligations in countries where we have partial support. Autocomply then sends your team notifications to remind them about due obligations.",
      isOpen: false,
      tag: 'settings'
    },
]

export const HelpFaqData = [
    {
      question: "How do I add new team members to my AutoComply account?",
      answer: "- To add new team members, navigate to the 'Teams' tab on the left menu bar, click 'Add Team Member' at the top right corner, and follow the prompts. You'll need to enter the team member’s details and set their initial role and permissions",
      isOpen: false,
      tag: 'dashboard'
    },
    {
      question: "What roles can I assign to team members in AutoComply?",
      answer: " - In AutoComply, you can assign the following roles: - **Admin:** Full access to all features and settings. - **Team Leader**: Canmanage team members and access most features but with some restrictions compared to Admin. - **Team Member:** Limited access, primarily focused on completing assigned tasks and accessing specific documents or obligations.",
      isOpen: false,
      tag: 'dashboard'
    },
    {
        question: "How do I set specific permissions for different users on my team?",
        answer: " - While adding a new user or managing an existing one, you can select specific permissions that control their access to certain documents, companies, or obligations. This ensures that team members only have access to the information they need for their role",
        isOpen: false,
        tag: 'dashboard'
    },
    {
        question: "Can I restrict access to certain documents or compliance items for specific team members?",
        answer: "- Yes, when adding or editing a user’s profile, you can select specific permissions that allow or restrict access to certain documents or compliance items. This helps in maintaining data security and ensuring that sensitive information is only accessible to authorized personnel.",
        isOpen: false,
        tag: 'settings'
    },
    {
        question: "What is the difference between an Admin, Team Leader, and Team Member role in AutoComply?",
        answer: " - **Admin:** Has full access to all features, including adding and removing team members, setting permissions, and accessing all documents and companies.- **Team Leader:** Can manage team members and access most features but with some restrictions compared to Admin, such as limited access to certain settings or confidential information.- **Team Member:** Has limited access, focused on completing tasks assigned to them and accessing specific documents or obligations they have permission for.",
        isOpen: false,
        tag: 'teams'
    },
    {
        question: "How can I assign access to specific companies within AutoComply?",
        answer: " - During the process of adding a new team member, you can assign them access to specific companies. This is done in the user setup where you choose which companies they should have access to. ",
        isOpen: false,
        tag: 'teams'
    },
    {
        question: "Where can I view and manage all team members and their roles?",
        answer: "- You can view and manage all team members and their roles within the 'Teams' tab on your AutoComply dashboard. This section provides an overview of all team members, their assigned roles, and their current permissions.",
        isOpen: false,
        tag: 'teams'
    },
    {
        question: "What should I consider when assigning roles and permissions to ensure data security?",
        answer: "   - Consider the following:- Assign the least amount of access necessary for a team member to complete their tasks.- Restrict access to sensitive or confidential information to only those who absolutely need it.- Regularly review and update roles and permissions to ensure they align with current responsibilities and organizational changes.",
        isOpen: false,
        tag: 'teams'
    },
    {
        question: "Can I change a team member's role or permissions after they have been added?",
        answer: "Yes, you can modify a team member’s role or permissions at any time by accessing their profile in the 'Teams' tab. This allows you to adjust their access as their responsibilities within the team change.",
        isOpen: false,
        tag: 'teams'
    },
    {
        question: "Is there a limit to the number of team members I can add to my AutoComply account?",
        answer: "The document does not specify a limit, but you should check your AutoComply subscription plan or contact support to verify if there are any restrictions based on your account type.",
        isOpen: false,
        tag: 'teams'
    },
    {
        question: "What happens if I remove a team member from AutoComply?",
        answer: "Removing a team member will revoke their access to the AutoComply platform, including all documents and companies they were previously assigned to. Their actions and document history, however, will remain in the system for auditing purposes.",
        isOpen: false,
        tag: 'teams'
    },
    {
        question: "How do I ensure a new team member has the correct permissions when added?",
        answer: "During the setup process, after you add a new team member, carefully review the permissions settings to ensure they match the tasks and responsibilities assigned to that individual. You can always adjust these permissions later if needed",
        isOpen: false,
        tag: 'teams'
    },
    {
        question: "Can I assign multiple roles to a single user in AutoComply?",
        answer: "No, each user can only have one role at a time (Admin, Team Leader, or Team Member). However, you can tailor their permissions within that role to fit their specific responsibilities.",
        isOpen: false,
        tag: 'teams'
    },
    {
        question: "What should I do if I encounter issues while adding or managing team members?",
        answer: "If you encounter issues, kindly reach out via the Help Center and our customer support team will be available to assist you.",
        isOpen: false,
        tag: 'teams'
    },
    {
        question: "How does AutoComply ensure that data security is maintained when adding and managing team members?",
        answer: "AutoComply allows you to assign precise roles and permissions to ensure that team members only have access to the information they need. This helps in maintaining high standards of data security and minimizing the risk of unauthorized access to sensitive information.",
        isOpen: false,
        tag: 'teams'
    },
]


export const morDataList = [
  {
    name: "Payment Localization",
    description: "Start selling how your customers prefer to buy, with local languages, currencies, and payment methods.",
    image: 'payment',
  },
  {
    name: "Sales Tax Compliance",
    description: "Never worry about global sales tax again, we’ll handle each and every local tax obligations for you automatically.",
    image: 'sales',
  },
  {
    name: "Fraud Prevention",
    description: "Mitigate your risk and avoid unnecessary penalties through our fraud protection and prevention infrastructure.",
    image: 'fraud',
  },
  {
    name: "Access Untapped Markets",
    description: "Penetrate new regions and maximize your revenue potential with our market insights and tailored strategies.",
    image: 'markets',
  },
  {
    name: "Dunning Management",
    description: "Minimize revenue loss, streamline payment recovery processes and maintain positive customer relationships",
    image: 'management',
  },
  {
    name: "Reliable Support",
    description: "We offer comprehensive assistance from onboarding and integration to ongoing maintenance and optimization.",
    image: 'support',
  },
  
]