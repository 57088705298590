export interface Config {
  dev: {
    domainName: string,
    signUpSignInFlowName: string,
    clientId: string,
    accessScope: string,
    emailVerificationFlowName: string,
    profileEditFlowName: string,
    passwordResetFlowName: string,
  },
  canary: {
    domainName: string,
    signUpSignInFlowName: string,
    clientId: string,
    accessScope: string,
    emailVerificationFlowName: string,
    profileEditFlowName: string,
    passwordResetFlowName: string,
  }
  prod: {
    domainName: string,
    signUpSignInFlowName: string,
    clientId: string,
    accessScope: string,
    emailVerificationFlowName: string,
    profileEditFlowName: string,
    passwordResetFlowName: string,
  }
}

export const B2cConfigs:Config = {
  dev:{
    domainName:'norebasebeta',
    signUpSignInFlowName:'B2C_1A_SIGNUP_SIGNIN',
    emailVerificationFlowName: 'B2C_1A_EMAILVERIFICATION',
    profileEditFlowName: 'B2C_1A_PROFILEEDIT',
    passwordResetFlowName:'B2C_1A_PasswordReset',
    clientId:'126b8618-9f63-49ee-9176-be05aeb8049b',
    accessScope:'https://norebasebeta.onmicrosoft.com/48e0b026-1c58-477a-bbb4-4e613f0aa817/access_as_user',
  },
  canary:{
    domainName:'norebasecanary',
    signUpSignInFlowName:'B2C_1A_SIGNUP_SIGNIN',
    emailVerificationFlowName: 'B2C_1A_EMAILVERIFICATION',
    profileEditFlowName: 'B2C_1A_PROFILEEDIT',
    passwordResetFlowName:'B2C_1A_PasswordReset',
    clientId:'0ecad3fd-4baf-4ac0-9803-54cd2803f4d6',
    accessScope:'https://norebasecanary.onmicrosoft.com/0ecad3fd-4baf-4ac0-9803-54cd2803f4d6/access_as_user'
  },
  prod:{
    domainName:'norebaselive',
    signUpSignInFlowName:'B2C_1A_SIGNUP_SIGNIN',
    emailVerificationFlowName: 'B2C_1A_EMAILVERIFICATION',
    profileEditFlowName: 'B2C_1A_PROFILEEDIT',
    passwordResetFlowName:'B2C_1A_PasswordReset',
    clientId:'79f84ab5-87d6-4c46-825f-dd06702eaecf',
    accessScope:'https://norebaselive.onmicrosoft.com/79f84ab5-87d6-4c46-825f-dd06702eaecf/access_as_user'
  }
}